<style lang="scss">
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 75%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
}
</style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="xl"
    v-model="showModal"
    :title="'Detail of Appointment Number: ' + appointmentData.id"
  >
    <div class="row" v-if="loadingData">
      <div class="col-12 text-center" style="padding: 10px">
        <loadingSpinner></loadingSpinner>
        <br />
        <h6>Please Wait, we are get your appointment details.</h6>
      </div>
    </div>
    <div class="row" v-if="hasData && !loadingData">
      <div class="col-5" style="padding: 10px">
        <ul style="display: flex; list-style: none">
          <li style="width: 100%">
            <h5>{{ appointmentData.service_name }}</h5>
            <h6 v-if="appointmentData.is_additional">
              Additional: {{ appointmentData.is_additional }}
            </h6>
            <div class="mb-2">
              <div
                class="badge badge-warning mr-2"
                style="color: white; cursor: pointer"
                v-if="appointmentData.appt_status == 'P'"
              >
                Pending
              </div>
              <div
                class="badge badge-success mr-2"
                style="color: white; cursor: pointer"
                v-if="appointmentData.appt_status == 'C'"
              >
                Completed
              </div>
              <div
                class="badge badge-info mr-2"
                style="color: white; cursor: pointer"
                v-if="appointmentData.appt_status == 'U'"
              >
                Unseen
              </div>
              <div
                class="badge badge-info mr-2"
                style="color: white"
                v-if="appointmentData.payment_type == 'C'"
              >
                Card Payment
              </div>

              <div
                class="badge badge-info mr-2"
                style="color: white"
                v-if="appointmentData.payment_type == 'O'"
              >
                On-site Payment
              </div>

              <div
                class="badge badge-info mr-2"
                style="color: white"
                v-if="appointmentData.payment_type == 'A'"
              >
                Abbreviation
              </div>
              <div
                class="badge badge-success mr-2"
                style="color: white"
                v-if="appointmentData.payment_status == 'P'"
              >
                Paid
              </div>
              <div
                class="badge badge-danger mr-2"
                style="color: white"
                v-if="appointmentData.payment_status == 'U'"
              >
                Unpaid
              </div>
              <div
                class="badge badge-success mr-2"
                style="color: white"
                v-if="appointmentData.payment_status == 'Refund'"
              >
                Refund
              </div>
              <div
                class="badge badge-danger"
                style="color: white"
                v-if="appointmentData.appt_status == 'Cancel'"
              >
                Canceled
              </div>
            </div>
          </li>
        </ul>

        <hr />
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-9 col-sm-8 col-8">
                <p class="text-success sub-header">
                  Appointment details
                  <!-- <a
                    v-if="
                      appointmentData.appt_status != 'Cancel' &&
                        !paymentLog &&
                        appointmentData.appointment_date != null
                    "
                    class="fa fa-calendar"
                    style="float: right"
               
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Appointment reschedule"
                  ></a> -->
                </p>

                <h4 v-if="appointmentData.appointment_date != null">
                  {{ appointmentData.appointment_date | sDateFormate }}
                </h4>
                <h6
                  class="text-info"
                  v-if="appointmentData.appointment_date != null"
                >
                  {{
                    appointmentData.start_time
                      | timeFormat(appointmentData.appointment_date)
                  }}
                </h6>
                <h4
                  style="font-size: 13px; font-weight: 400"
                  v-if="appointmentData.appointment_date == null"
                >
                  N/A
                </h4>
              </div>
              <div
                class="col-md-3 col-sm-4 col-4"
                style="border-left: 0.5px solid #ccc"
              >
                <div class="text-center" style="margin-top: 15px">
                  <p>Total Amount</p>
                  <h4 class="text-primary">
                    ${{ appointmentData.total_amount | toFixedTwoDigit }}
                  </h4>
                </div>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-12">
                <p class="text-success sub-header">Personal Details</p>
                <ul class="list-unstyled">
                  <li>
                    <label for="">First Name:</label>
                    <label for="" class="detail">{{
                      appointmentData.fname
                    }}</label>
                  </li>
                  <li>
                    <label for="">Last Name: </label>
                    <label for="" class="detail">{{
                      appointmentData.lname
                    }}</label>
                  </li>
                  <li>
                    <label for="">Email:</label>
                    <label for="" class="detail">{{
                      appointmentData.email
                    }}</label>
                  </li>
                  <!-- <li>
                    <label for="">Date of Birth: </label>
                    <label for="" class="detail">{{
                      appointmentData.dob
                    }}</label>
                  </li> -->

                  <li>
                    <label for="">Contact NUmber: </label>
                    <label for="" class="detail">{{
                      appointmentData.phone_no
                    }}</label>
                  </li>
                </ul>
              </div>
            </div>
            <hr
              v-if="
                appointmentData.abbreviation_code != '' &&
                  appointmentData.abbreviation_code != null
              "
            />
            <div
              class="row"
              v-if="
                appointmentData.abbreviation_code != '' &&
                  appointmentData.abbreviation_code != null
              "
            >
              <div class="col-12">
                <p class="text-success sub-header">Abbreviation Code</p>
                <ul class="list-unstyled">
                  <li>
                    <label for="">Code:</label>
                    <label for="" class="detail">{{
                      appointmentData.abbreviation_code
                    }}</label>
                  </li>
                  <li>
                    <label for="">Name:</label>
                    <label for="" class="detail">{{
                      appointmentData.company_name
                    }}</label>
                  </li>
                  <li>
                    <label for="">Address: </label>
                    <label for="" class="detail">{{
                      appointmentData.company_address
                    }}</label>
                  </li>
                </ul>
              </div>
            </div>
            <hr
              v-if="
                appointmentData.location_job != '' &&
                  appointmentData.location_job != null &&
                  appointmentData.payment_type == 'CP'
              "
            />
            <div
              class="row"
              v-if="
                appointmentData.location_job != '' &&
                  appointmentData.location_job != null &&
                  appointmentData.payment_type == 'CP'
              "
            >
              <div class="col-12">
                <p class="text-success sub-header">Company Details</p>
                <ul class="list-unstyled">
                  <li>
                    <label for="">Name:</label>
                    <label for="" class="detail">{{
                      appointmentData.job_client_name
                    }}</label>
                  </li>
                  <li>
                    <label for="">Address: </label>
                    <label for="" class="detail">{{
                      appointmentData.job_client_address
                    }}</label>
                  </li>
                </ul>
              </div>
            </div>
            <hr
              v-if="
                appointmentData.payment_type == 'O' ||
                  appointmentData.payment_type == 'A'
              "
            />

            <div
              class="row"
              v-if="
                (appointmentData.payment_type == 'O' ||
                  appointmentData.payment_type == 'A' ||
                  appointmentData.payment_type == 'CP') &&
                  !showQrCode &&
                  !(appointmentData.appt_status == 'Cancel') &&
                  !paymentLog
              "
            >
              <div class="col-12">
                <div class="form-group">
                  <label for="">Payment Status</label>
                  <div class="input-group">
                    <select
                      @change="
                        updatePaymentStatus(
                          appointmentData.id,
                          appointmentData.payment_status
                        )
                      "
                      class="form-control"
                      v-model="appointmentData.payment_status"
                    >
                      <option
                        value="U"
                        :selected="appointmentData.payment_status == 'U'"
                      >
                        Unpaid
                      </option>
                      <option
                        value="P"
                        :selected="appointmentData.payment_status == 'P'"
                      >
                        Paid
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="showQrCode">
              <div class="col-12">
                <img
                  :src="appointmentData.barcode_base64"
                  style="height: 75px; width: 100%"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-7">
        <div class="refund-service mt-5">
          <button
          v-if=" appointmentData.appt_status != 'Cancel' &&
                        !paymentLog &&
                        appointmentData.appointment_date != null"
            class="btn btn-secondary mr-2"
            :disabled="appointmentData.location_job"
            @click="reschedule"
          >
           Reschedule
          </button>
          <button
            v-if="
              currentUser.u.is_service_provider &&
                is_archival == false &&
                (appointmentData.service_code == 'fdle' ||
                  appointmentData.service_code == 'fingerprintcard')
            "
            class="btn btn-secondary mr-2"
            @click="
              archiveAppointment(
                appointmentData.id,
                token,
                appointmentData.client_id,
                true
              )
            "
          >
            Archive
          </button>
          <button
            v-if="currentUser.u.is_service_provider && is_archival == false"
            class="btn btn-secondary mr-2"
            @click="
              changeLocation(
                appointmentData.id,
                token,
                appointmentData.client_id,
                appointmentData
              )
            "
          >
            Change Location
          </button>
          <button
            class="btn btn-secondary mr-2"
            v-if="
              appointmentData.payment_status == 'P' &&
                is_archival == false &&
                currentUser.u.is_service_provider &&
                appointmentData.appt_status == 'Cancel'
            "
            @click="refund(appointmentData.id, token)"
          >
            Refund
          </button>
          <button
            class="btn btn-secondary mr-2"
            v-if="
              appointmentData.appt_status != 'Cancel' &&
                is_archival == false &&
                appointmentData.appt_status != 'c' &&
                appointmentData.has_additional_rate == 1 &&
                !appointmentData.is_drug_testing &&
                !paymentLog
            "
            @click="
              additionalService(
                appointmentData.id,
                token,
                appointmentData.service_id,
                appointmentData.client_id,
                true
              )
            "
          >
            Additional card
          </button>
          <button
            class="btn btn-secondary"
            v-if="
              appointmentData.appt_status != 'Cancel' &&
                is_archival == false &&
                appointmentData.appt_status != 'c' &&
                appointmentData.appointment_date != null
            "
            @click="
              additionalnewService(
                appointmentData.id,
                token,
                appointmentData.client_id,
                true
              )
            "
          >
            Add service
          </button>
        </div>
        <hr class="mt-24" />
        <h6>Logs</h6>
        <div class="log">
          <v-table :columns="columns">
            <tr v-for="(item, index) in getappointmentLog" :key="index">
              <td>{{ ++index }}</td>
              <td style="width: 24%">
                {{ datetimeFormatAmPm(item.created_at) }}
              </td>
              <td>{{ item.description }}</td>
              <td style="width: 27%">{{ item.username }}</td>
            </tr>
          </v-table>
        </div>
      </div>
    </div>
    <div class="row" v-if="!hasData && !loadingData">
      <div class="col-md-12 col-12" style="padding: 10px">
        <h5>Sorry, we didn't found your appointment details</h5>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import loadingSpinner from "@/components/frontend/spinner.vue";
import vTable from "@/includes/table/Datatable";

import moment from "moment";
import { mapGetters } from "vuex";
import {
  SET_APPOINTMENT_FOR_RESCHEDULE,
  SET_TIME_ZONE,
  SET_SERVICE_ID,
  SET_BOOK_NOW_FROM_SP,
  SET_ADD_RATE_QTY,
  UPDATE_PAYMENT_TYPE,
  SET_RATE_TOTAL_AMOUNT,
  APPLICANTDETAIL
} from "@/core/services/store/mutations.type";
// import * as PDF417 from "pdf417-generator";
import {
  UPDATE_APPOINTMENT_PAYMENT_STATUS,
  APPOINTMENT_REFUND
} from "@/core/services/store/actions.type";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    loadingData: {
      type: Boolean,
      default: false
    },
    hasData: {
      type: Boolean,
      default: false
    },
    showQrCode: {
      type: Boolean,
      default: false
    },
    indexNum: {
      type: Number,
      default: -1
    },
    appointmentData: {
      type: Object,
      default: function() {
        return [];
      }
    },
    token: {
      type: String,
      default: ""
    },
    paymentLog: {
      type: Boolean,
      default: false
    },
    is_archival: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [
        {
          name: "sn",
          width: "",
          label: "S.N",
          class: ""
        },
        {
          name: "date",
          width: "",
          label: "Date",
          class: ""
        },
        {
          name: "description",
          width: "",
          label: "Description",
          class: ""
        },
        {
          name: "created_by",
          width: "",
          label: "Updated By",
          class: ""
        }
      ]
    };
  },

  components: {
    loadingSpinner,
    vTable
  },
  computed: {
    ...mapGetters(["currentUser", "getappointmentLog"])
  },
  mounted() {
    document.body.addEventListener("keyup", e => {
      if (e.keyCode == 27) {
        this.$emit("closemodal");
      }
    });
  },
  methods: {
    close() {
      this.$emit("closemodal");
    },
    updatePaymentStatus(appId, payment_status) {
      this.$store
        .dispatch(UPDATE_APPOINTMENT_PAYMENT_STATUS, {
          app_id: appId,
          payment_status: payment_status
        })
        .then(res => {
          this.$toastr.s(res.msg);
          this.$emit("closemodal");
          this.$emit(
            "updatepaymentstatus",
            this.indexNum,
            this.appointmentData.payment_status,
            this.appointmentData.is_drug_testing
          );
        })
        .catch(err => {
          this.$toastr.e(err.msg);
        });
    },

    additionalService(
      app_id,
      token,
      service_id,
      client_id,
      enable_on_site_payment
    ) {
      this.$store.commit(SET_SERVICE_ID, service_id);
      this.$store.commit(SET_BOOK_NOW_FROM_SP, true);
      this.$store.commit(SET_ADD_RATE_QTY, 0);
      this.$store.commit(SET_RATE_TOTAL_AMOUNT, 0);

      this.$store.commit(SET_TIME_ZONE, this.appointmentData.client_timezone);
      this.$store.commit(UPDATE_PAYMENT_TYPE, "C");

      this.$router.push({
        name: "client.additional.appointmnet",
        params: {
          token: token,
          app_id: app_id,
          id: client_id,
          onsite: enable_on_site_payment
        }
      });
    },
    archiveAppointment(app_id, token, client_id, enable_on_site_payment) {
      this.$store.commit(SET_TIME_ZONE, this.appointmentData.client_timezone);
      this.$store.commit(UPDATE_PAYMENT_TYPE, "C");

      this.$router.push({
        name: "client.archive.appointment",
        params: {
          token: token,
          app_id: app_id,
          client_id: client_id,
          onsite: enable_on_site_payment
        }
      });
    },
    additionalnewService(app_id, token, service_id, enable_on_site_payment) {
      this.$store.commit(SET_TIME_ZONE, this.appointmentData.client_timezone);
      this.$store.commit(SET_RATE_TOTAL_AMOUNT, 0);

      this.$router.push({
        name: "client.new.appointmnet.service",
        params: {
          token: token,
          app_id: app_id,
          id: service_id,
          onsite: enable_on_site_payment
        }
      });
    },
    changeLocation(app_id, token, service_id, app) {
      this.$store.commit(APPLICANTDETAIL, app);

      this.$router.push({
        name: "client.change.location",
        params: {
          token: token,
          app_id: app_id,
          id: service_id
        }
      });
    },
    refund(app_id, token) {
      var data = {
        app_id: app_id,
        token: token
      };
      let message = `You want to Refund Appointment no  ${app_id}`;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: message,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store
              .dispatch(APPOINTMENT_REFUND, data)
              .then(res => {
                if (res.code == 200) {
                  this.$toastr.s(res.msg);
                  this.appointmentData.payment_status = "Refund";
                  this.$emit("reLoadTable", this.indexNum);
                }
              })
              .catch(errs => {
                this.errorMsg = errs.msg;
              });
          }
        });
    },
    reschedule() {
      this.$store.commit(SET_APPOINTMENT_FOR_RESCHEDULE, this.appointmentData);
      this.$store.commit(SET_TIME_ZONE, this.appointmentData.client_timezone);
      this.$router.push({
        name: "client.appointment.reschedule",
        params: {
          token: this.token,
          app_id: this.appointmentData.id,
          client_slug: this.currentUser.c.slug,
          id: this.appointmentData.client_id
        }
      });
    }
  },

  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function(value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      return moment(String(fullDate)).format("h:mm a");
    },
    sDateFormate: function(date) {
      if (date == null) {
        return;
      }
      return moment(String(date)).format("dddd, MMMM DD, YYYY");
    }
  }
};
</script>
